<template>
	<div>
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信或支付宝中打开</h4>
		</div>
		<div class="box" v-if="productInfo">
			<div class="price">
				<span>{{ priceInt(productInfo.premium) }}</span>
				<span class="price_float">.{{ priceFloat(productInfo.premium) }}</span>
			</div>

			<div class="order">
				<div class="title">订单信息</div>
				<div class="order_item">
					<span class="label">订单号:</span><span>{{ productInfo.billCode }}</span>
				</div>
				<div class="order_item">
					<span class="label">产品名称:</span><span>{{ productInfo.proName }}</span>
				</div>
				<div class="order_item" v-if="!showDetail && productInfo.payState"><span class="label">订单状态:</span><span>已支付</span></div>
			</div>
			<div class="pay" v-if="showDetail">
				<div class="title">支付方式</div>
				<div class="pay_item" v-if="!isDev">
					<div class="flex"><img src="@/assets/icon/yuerpay.svg" class="checked-icon" />余额支付(仅用于测试使用)</div>
					<div class="flex">
						<span>￥{{ priceInt(money) }}.{{ priceFloat(money) }}</span
						><img src="@/assets/image/success.svg" class="checked-icon" />
					</div>
				</div>
				<div class="pay_item" v-if="env === 'wx' || (!isDev && !env)">
					<div class="flex"><img src="@/assets/icon/wxpay.svg" class="checked-icon" />微信支付<span v-if="!isDev">(当前环境不可用)</span></div>
					<img v-if="env === 'wx' && isDev" src="@/assets/image/success.svg" class="checked-icon" />
				</div>
				<div class="pay_item" v-if="env === 'zfb' || (!isDev && !env)">
					<div class="flex"><img src="@/assets/icon/zfbpay.svg" class="checked-icon" />支付宝支付<span v-if="!isDev">(当前环境不可用)</span></div>
					<img src="@/assets/image/success.svg" class="checked-icon" v-if="env === 'zfb' && isDev" />
				</div>
			</div>
			<van-button v-if="showDetail" :class="['btn', { ' wx_color': env === 'wx' }]" @click="pay">立即支付</van-button>
		</div>
	</div>
</template>

<script>
import { http_getBillDetails, http_getAccountBalance, http_alipayPayment, http_balancePayment, http_getWechatAppId, http_wechatJsApiPay } from '@/request/pay.js';
import { Toast, Button } from 'vant';
import { getOpenId } from '@/request/api';
export default {
	components: {
		[Toast.name]: Toast,
		[Button.name]: Button,
	},
	data() {
		return {
			weChatWarningShow: false,
			H5Pay: false, //余额支付
			showDetail: false,
			money: 0,
			send: {
				appId: '',
				outBillId: '', //订单号
				timestamp: '', //时间戳
				sign: '', //签名
				returnUrl: '', //支付成功返回地址
			},
			productInfo: null, //订单信息
			env: null, //环境
			openId: '', //openId
			invokeParams: {}, //WeixinJSBridge.invoke参数
			code: '', //微信公众号授权code
			isDev: true, //是否是正式环境
		};
	},

	watch: {
		// 微信支付需要提前获取appid和code，授权code会重定向
		$route: {
			handler(val) {
				// 获取路由参数
				const { appId, outBillId, timestamp, sign, returnUrl, code } = val.query;
				this.isDev = this.$base.isDev();
				this.send.appId = appId;
				this.send.outBillId = outBillId;
				this.send.sign = sign;
				this.send.timestamp = timestamp;
				this.send.returnUrl = returnUrl;
				this.code = code;
				this.env = this.$base.getEnv();
				// 如果是正式环境并且不是用支付宝/微信打开
				if (this.isDev && !this.env) {
					this.weChatWarningShow = true;
				} else {
					this.weChatWarningShow = false;
					//获取订单详情,初始化数据
					this.getOrderDetail();
				}
			},
			immediate: true,
		},
	},

	methods: {
		// 获取订单详情
		async getOrderDetail() {
			const { data } = await http_getBillDetails(this.send);
			this.productInfo = data;
			// 判断订单状态,billState订单状态为2和payState支付状态为0的时候才可以支付
			if (data.billState === 2 && data.payState === 0) {
				this.showDetail = true;
				this.getEnvData();
			}
		},
		// 根据环境获取数据
		getEnvData() {
			// 测试环境只能用余额支付
			if (!this.isDev) {
				this.H5Pay = true;
				// 获取余额
				this.getAccount();
			} else if (this.env === 'wx') {
				if (this.code) {
					// 3、重定向进来获取openid
					this.getOpenId(this.code);
				} else {
					// 1、获取微信公众号appid
					http_getWechatAppId(this.send).then(res => {
						// 2. 拿着appId，去公众号换取code
						this.getWxCode(res.data);
					});
				}
			}
		},
		// 获取余额
		async getAccount() {
			const { data } = await http_getAccountBalance(this.send);
			this.money = data;
		},
		// 立即支付
		async pay() {
			// 判断订单状态,billState订单状态为2和payState支付状态为0的时候才可以支付
			const { billState, payState } = this.productInfo;
			if (billState !== 2 && payState !== 0) {
				return Toast('订单已失效');
			}
			if (this.H5Pay) {
				// 余额支付
				this.handleH5Pay();
			} else {
				if (this.env === 'wx') {
					this.handleWxPay();
				} else {
					this.handleZfbPay();
				}
			}
		},
		// 余额支付
		async handleH5Pay() {
			// 调用接口查询最新的余额去判断余额是否大于订单金额
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '获取支付信息...',
			});
			await this.getAccount();
			if (this.money >= this.productInfo.premium) {
				await http_balancePayment(this.send);
				Toast.clear();
				// 支付成功跳转页面
				if (this.send.returnUrl) {
					window.location.href = decodeURIComponent(this.send.returnUrl);
				} else {
					this.$router.push('/payPlatformSuccess');
				}
			} else {
				Toast.clear();
				Toast('余额不足');
			}
		},
		// 支付宝支付
		async handleZfbPay() {
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '获取支付信息...',
			});
			const { data } = await http_alipayPayment({ ...this.send, returnUrl: `${window.location.origin}/payPlatformSuccess?returnUrl=${encodeURIComponent(this.send.returnUrl)}` });
			Toast.clear();
			const div = document.createElement('div');
			div.id = 'alipay';
			div.innerHTML = data;
			document.body.appendChild(div);
			document.querySelector('#alipay').children[0].submit(); // 执行后会唤起支付宝
		},

		/**
		 * 微信支付
		 * 1、获取微信公众号appid
		 * 2、根据appid去获取公众号获取code
		 * 3、根据code去那openid
		 * 4、点击立即支付调用微信jsapi支付
		 * 5、与微信搭桥，调用支付方法，搭桥前要解决微信内置对象WeixinJSBridge报错问题(信webview注入钩子有时序问题：在WeixinJSBridge还未注入之前，就已经成功注入其它依赖于WeixinJSBridge的其它XX模块。在XX模块中调用WeixinJSBridge就会失败),调用WeixinJSBridge.invoke
		 */

		// 2. 拿着appId，去公众号换取code
		getWxCode(wxAppId) {
			let redictUrl = encodeURIComponent(window.location.href);
			let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wxAppId}&redirect_uri=${redictUrl}&response_type=code&scope=snsapi_base#wechat_redirect`;
			window.location.replace(url);
		},
		//3、拿着code，通过接口请求openId
		getOpenId(code) {
			getOpenId(code).then(res => {
				this.openId = res.openid;
			});
		},
		//4、点击立即支付调用微信支付
		async handleWxPay() {
			const res = await http_wechatJsApiPay({ ...this.send, openId: this.openId });
			this.invokeParams = res.data;

			//5、微信搭桥，调用WeixinJSBridge.invoke支付
			if (typeof WeixinJSBridge == 'undefined') {
				if (document.addEventListener) {
					document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
				} else if (document.attachEvent) {
					document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
					document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
				}
			} else {
				this.onBridgeReady();
			}
		},
		// JSAPI支付方法
		onBridgeReady() {
			let that = this;
			WeixinJSBridge.invoke('getBrandWCPayRequest', this.invokeParams, function (res) {
				if (res.err_msg == 'get_brand_wcpay_request:ok') {
					// 使用以上方式判断前端返回,微信团队郑重提示：
					//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
					if (that.send.returnUrl) {
						window.location.href = decodeURIComponent(that.send.returnUrl);
					} else {
						that.$router.push('/payPlatformSuccess');
					}
				} else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
					Toast.fail('用户取消支付');
				} else {
					Toast.fail('支付失败');
				}
			});
		},

		// 价格方法
		priceInt(val) {
			let result = '0';
			let fullPrice = this.$base.floatPrice(val ?? 0);
			if (fullPrice) {
				result = fullPrice.split('.')[0];
			}
			return result;
		},
		priceFloat(val) {
			let result = '00';
			let fullPrice = this.$base.floatPrice(val ?? 0);
			if (fullPrice) {
				result = fullPrice.split('.')[1];
			}
			return result;
		},
	},
};
</script>

<style lang="scss" scoped>
.weChat_warning {
	font-size: 0.4rem;
	text-align: center;
}
.box {
	margin-top: 1rem;
	text-align: center;
	padding: 0 0.6rem;
}
.price {
	.price_float {
		font-size: 0.8rem;
	}
}
.order {
	font-size: 0.24rem;
	text-align: left;
	padding: 0.2rem 0;
	margin-top: 0.6rem;
	border-top: solid 1px rgba(0, 0, 0, 0.1);

	.order_item {
		padding: 0.2rem 0;
		display: flex;
		justify-content: space-between;
	}
}
.btn {
	font-size: 0.4rem;
	width: 80%;
	background-color: $b_color_main;
	color: $background_color1;
	border-radius: 0.1rem;
	padding: 0.2rem 0;
	margin: 0.8rem auto 0;
}
.wx_color {
	background-color: $color_green;
}
.billText {
	font-size: 0.4rem;
}
.pay {
	font-size: 0.24rem;
	border-top: solid 1px rgba(0, 0, 0, 0.1);
	padding: 0.2rem 0;

	.pay_item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.1rem 0;
	}
	.checked-icon {
		width: 0.4rem;
		height: 0.4rem;
		margin-right: 0.1rem;
	}
	.flex {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.title {
	font-size: 0.3rem;
	text-align: left;
	font-weight: 600;
	padding-bottom: 0.2rem;
}
</style>
