import { post } from './http';
// 获取订单详情
export function http_getBillDetails(data) {
	return post(`/insurance/openApi/cashier/getBillDetails`,data);
}
//获取账户余额
export function http_getAccountBalance(data) {
	return post(`/insurance/openApi/cashier/getAccountBalance`,data);
}
//支付宝支付
export function http_alipayPayment(data) {
	return post(`/insurance/openApi/cashier/alipayPayment`,data);
}
//余额支付
export function http_balancePayment(data) {
	return post(`/insurance/openApi/cashier/balancePayment`,data);
}
//获取微信公众号appId
export function http_getWechatAppId(data){
	return post(`/insurance/openApi/cashier/getWechatAppId`,data);
}
//微信JSAPI支付
export function http_wechatJsApiPay(data){
	return post(`/insurance/openApi/cashier/wechatJsApiPay`,data);
}